import React, {useState, useContext} from 'react';
import axios from 'axios';
import { GlobalContext } from "../GlobalContext";
import Navbar from './navbar.component';
import {Link} from 'react-router-dom';

const Login = (props) => {
    const [ formUsername, setFormUsername ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ error, setError ] = useState('')
    const {setUsername} = useContext(GlobalContext)
    const submitLogin = (e) => {
        e.preventDefault()
        axios.post('/auth/login', {formUsername, password})
        .then(r => {
            if(r.data.passed){
                setUsername(r.data.username)
                if(props.location.state && props.location.state.prevUrl && !props.location.state.prevUrl.includes("/login")){
                    window.location=props.location.state.prevUrl
                }else{
                    window.location = "/"
                }
            } else{
                setError(r.data.msg)
            }

        })
    }
    return(
        <div>
            <Navbar />
            <br /><br />
            <div className="page-background">
                <div className="login-page">
                    <div className="header">Log In</div><br />
            <div>{error}</div>
        <form onSubmit={submitLogin}>
            <input value={formUsername} onChange={(e) => setFormUsername(e.target.value)} placeholder="Username or email" /><br />
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="password"/><br />
            <input type="submit" /><br />
            <Link to="/forgotpassword">Forgot password?</Link><br />
            <Link to="/signup">Create Account</Link>
        </form>
        </div>
        </div>
        </div>
    )
}

export default Login