import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import Navbar from './navbar.component';
import { GlobalContext } from "../GlobalContext";
import ImageById from './image-by-id.component';
import Cookies from 'universal-cookie';
import getTranslation from '../helpers/getTranslation';
import factions from '../data/factions.json'
//comment to redeploy
const ListDisplay = (props) => {
        return(
        <table>
            <tr><th style={{width: '250px'}}></th><th style={{width: '50px'}}>W</th><th>L</th><th></th><th></th></tr>
        {props.lists.map(l => 
            <tr className="user-list-row">
                <td><Link style={{textDecoration: 'none'}} className="user-list-name" to={`/listbuilder/${props.factionName}/${l.id}`}><div style={{textDecoration: 'underline'}}>{l.name} ({l.points})</div><div style={{fontSize: '.7rem', opacity: '.8', lineHeight: 'normal', textDecoration: 'none'}}>{l.date}</div></Link></td>
                <td><span className="increment-button" onClick={() => props.decrementWins(l.id, l.wins)}>▼</span>{l.wins != null && l.wins}{l.wins == null && "0"}<span className="increment-button" onClick={() => props.incrementWins(l.id, l.wins)}>▲</span></td>
                <td><span className="increment-button" onClick={() => props.decrementLosses(l.id, l.losses)}>▼</span>{l.losses != null && l.losses}{l.losses == null && "0"}<span className="increment-button" onClick={() => props.incrementLosses(l.id, l.losses)}>▲</span></td>
                <td><Link style={{textDecoration: 'none'}} to={`/viewlist/${l.id}`}>💬</Link></td>
                <td><button style={{cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0}} onClick={() => props.deleteList(l.id)} className="delete-button">X</button></td>
            </tr>)}
        </table>
    )
}

const ShatterpointListDisplay = (props) => {
    return(
    <table>
        <tr><th style={{width: '250px'}}></th><th style={{width: '50px'}}>W</th><th>L</th><th></th><th></th></tr>
    {props.lists.map(l => 
        <tr className="user-list-row">
            <td><Link className="user-list-name" to={`/shatterpoint/listbuilder/${l.id}`}>{l.name}<div style={{fontSize: '.7rem', opacity: '.8', lineHeight: 'normal'}}>{l.date}</div></Link></td>
            <td><span className="increment-button" onClick={() => props.decrementWins(l.id, l.wins, true)}>▼</span>{l.wins != null && l.wins}{l.wins == null && "0"}<span className="increment-button" onClick={() => props.incrementWins(l.id, l.wins, true)}>▲</span></td>
            <td><span className="increment-button" onClick={() => props.decrementLosses(l.id, l.losses, true)}>▼</span>{l.losses != null && l.losses}{l.losses == null && "0"}<span className="increment-button" onClick={() => props.incrementLosses(l.id, l.losses, true)}>▲</span></td>
            <td><span style={{cursor: 'pointer'}} onClick={() => props.deleteList(l.id, true)} className="delete-button">X</span></td>
        </tr>)}
    </table>
)
}

const UserLists = () => {
    const [ lists, setLists ] = useState([])
    const [ spLists, setSpLists ] = useState([])
    const [ email, setEmail ] = useState('')
    const [ units, setUnits ] = useState([])
    const [ commands, setCommands ] = useState([])
    const [ upgrades, setUpgrades ] = useState([])
    const [ customFactions, setCustomFactions ] = useState([])
    const [ customAffiliations, setCustomAffiliations ] = useState([])
    const [ supporterUntil, setSupporterUntil ] = useState(null)
    const [ faction, setFaction ] = useState(null)
    const {supporter, setSupporter, slimUpgradeSlots, setSlimUpgradeSlots, setSlimUnits, slimUnits, showUnitImages, setShowUnitImages, scrollColumns, setScrollColumns, userId, setUserId, ignoreCollection, setIgnoreCollection} = useContext(GlobalContext)
    const cookies = new Cookies();
    setUserId(cookies.get('id'))


    useEffect(() => {

        
        const unitsToInit = () => {

            var pwUnits = [{
                selectorId: "first_med_rect_atf",
                type: "med_rect_atf"
            }]
            console.log("Ads API is initialized")
            window.ramp.destroyUnits("all").then((function() {
               window.ramp.addUnits(pwUnits).then((function() {
                if (!supporter) {
                    window.ramp.displayUnits()
                }
                }
                ))
           }
           )).catch((function(e) {
              window.ramp.displayUnits()
              console.log(e)
          }
          ))
         }

        const rampInterval = window.setInterval(() => {
            if (typeof window.ramp.addUnits !== 'undefined') {
                window.clearInterval(rampInterval);
                console.log('pushing units')
                if (!supporter) {
                    window.ramp.que.push(unitsToInit)
                }           
            }
        }, 100);
       
    }, [])

    useEffect(() => {
        axios.get('/spapi/my-lists')
        .then(r => setSpLists(r.data))
        axios.get('/api/my-lists')
        .then(r => setLists(r.data))
        axios.get('/api/user')
        .then(r => {
            setEmail(r.data.email)
            setSupporter(r.data.supporter)
            setSupporterUntil(r.data.supporter_until)
        })
        axios.get('/api/my-units')
        .then(r => setUnits(r.data))
        axios.get('/api/my-commands')
        .then(r => setCommands(r.data))
        axios.get('/api/my-upgrades')
        .then(r => setUpgrades(r.data))
        axios.get('/api/my-factions')
        .then(r => setCustomFactions(r.data))
        axios.get('/api/my-affiliations')
        .then(r => setCustomAffiliations(r.data))
    }, [])
    const updateSlimUpgradeSlots = (checked) => {
        setSlimUpgradeSlots(checked)
        axios.post('/api/update-upgrade-slot-preference', {slimUpgradeSlots: checked})
    }
    
    const updateSlimUnits = (checked) => {
        setSlimUnits(checked)
        axios.post('/api/update-slim-units-preference', {slimUnits: checked})
    }
    
    const updateShowUnitImages = (checked) => {
        setShowUnitImages(checked)
        axios.post('/api/update-show-unit-images-preference', {showUnitImages: checked})
    }

    const updateScrollColumns = (checked) => {
        setScrollColumns(checked)
        axios.post('/api/update-scroll-columns-preference', {scrollColumns: checked})
    }
    
    const updateIgnoreCollection = (checked) => {
        setIgnoreCollection(checked)
        axios.post('/api/update-ignore-collection-preference', {ignoreCollection: checked})
    }

    const deleteList = (id, shatterpoint=false) => {
        const api = shatterpoint ? '/spapi/' : '/api/'
        axios.post(`${api}delete-list/${id}`)
        .then(() => {            
            axios.get(`${api}my-lists`)
            .then(r => {
                if (shatterpoint) {
                    setSpLists(r.data)
                } else {
                    setLists(r.data)
                }
            })
        })
    }
    const checkPatreon = () => {
        axios.post('/api/update-patreon', {email})
        .then(r => {
            setSupporter(r.data.supporter)
        })
    }

    const incrementWins = (list, wins, shatterpoint=false) => {
        var arr = shatterpoint ? [...spLists] : [...lists]
        arr.find(l => l.id == list).wins = wins ? wins + 1 : 1
        const newWins = arr.find(l => l.id == list).wins
        if (shatterpoint) {
            setSpLists(arr)
        } else {
            setLists(arr)
        }
        
        const api = shatterpoint ? '/spapi/' : '/api/'
        axios.post(`${api}update-wins`, {list, wins: newWins})
    }
    
    const decrementWins = (list, wins, shatterpoint=false) => {
        var arr = shatterpoint ? [...spLists] : [...lists]
        arr.find(l => l.id == list).wins = wins ? wins - 1 : 0
        const newWins = arr.find(l => l.id == list).wins
        if (shatterpoint) {
            setSpLists(arr)
        } else {
            setLists(arr)
        }
        const api = shatterpoint ? '/spapi/' : '/api/'
        axios.post(`${api}update-wins`, {list, wins: newWins})
    }
    
    const incrementLosses = (list, losses, shatterpoint=false) => {
        var arr = shatterpoint ? [...spLists] : [...lists]
        arr.find(l => l.id == list).losses = losses != null ? losses + 1 : 1
        const newLosses = arr.find(l => l.id == list).losses
        if (shatterpoint) {
            setSpLists(arr)
        } else {
            setLists(arr)
        }
        const api = shatterpoint ? '/spapi/' : '/api/'
        axios.post(`${api}update-losses`, {list, losses: newLosses})
    }
    const decrementLosses = (list, losses, shatterpoint=false) => {
        var arr = shatterpoint ? [...spLists] : [...lists]
        arr.find(l => l.id == list).losses = losses ? losses - 1 : 0
        const newLosses = arr.find(l => l.id == list).losses
        if (shatterpoint) {
            setSpLists(arr)
        } else {
            setLists(arr)
        }
        const api = shatterpoint ? '/spapi/' : '/api/'
        axios.post(`${api}update-losses`, {list, losses: newLosses})
    }
    const uploadPhoto = async (e) => {
        const res = await axios.post('/api/update-avatar')        
        await axios.put(res.data.imageUrl, e.target.files[0], { headers: { "Content-Type": "multipart/form-data"}})   
        await axios.get(`/api/doctor-avatar-images`)
        window.location.reload()
    }
    
    const deleteUnit = (unit) => {
        axios.post('/api/delete-unit', {unit: unit})
        .then(() => window.location = "/profile/lists")
    }
    const deleteCommand = (command) => {
        axios.post('/api/delete-command', {command: command})
        .then(() => window.location = "/profile/lists")
    }
    const deleteUpgrade = (upgrade) => {
        axios.post('/api/delete-upgrade', {upgrade: upgrade})
        .then(() => window.location = "/profile/lists")
    }
    
    const deleteFaction = (faction) => {
        axios.post('/api/delete-faction', {faction: faction})
        .then(() => window.location = "/profile/lists")
    }
    
    const deleteAffiliation = (affiliation) => {
        axios.post('/api/delete-affiliation', {affiliation: affiliation})
        .then(() => window.location = "/profile/lists")
    }

    return(
        <div>
            <Navbar />
            <div className="page-background">
            <br /><br /><br /><br /><br />
            <div className="profile-page">
                
                <div className="user-lists">
                    <div className="header">{getTranslation('armies')}</div>    
                    <div style={{textAlign: 'center'}}>Select faction to filter</div>                
                    <div style={{margin: '6px'}} className="home-page-faction-select">
                        <div style={{textAlign: 'center'}}>
                        {factions && factions.filter(f => f.name !== "Neutral").map(f => {
                                    return <button style={{opacity: faction === f.id || !faction ? 1 : .35, background: 'none', border: 'none', cursor: 'pointer'}} onClick={() => setFaction(faction === f.id ? null : f.id)} key={f.id}><img width="75" height={f.id == 5 ? "66" : "75"} style={{width: '75px', height: f.id == 5 ? 'auto' : '75px'}} alt={f.name} src={`https://d26oqf9i6fvic.cloudfront.net/factionlogos/${f.id}.webp?ver=1`} /></button>        
                        })
                        }</div>
                    </div>
                    <div id="first_med_rect_atf"></div>
                    {factions.filter(f => f.name !== "Neutral" && (faction == f.id || !faction) && lists.some(l => l.faction_fkey == f.id)).map(faction => 
                        <div key={faction.id}>
                            <div className="user-list-faction-header">{faction.name}</div>
                            <ListDisplay lists ={lists.filter(l => l.faction_fkey == faction.id && !l.template_type)} factionName = {faction.name} deleteList = {deleteList} incrementWins = {incrementWins} incrementLosses = {incrementLosses} decrementWins = {decrementWins} decrementLosses = {decrementLosses} />
                        </div>
                    )}
                    
                    {spLists.length > 0 &&
                        <div>
                            <div className="user-list-faction-header">Shatterpoint</div>
                            <ShatterpointListDisplay lists={spLists} deleteList = {deleteList} incrementWins = {incrementWins} incrementLosses = {incrementLosses} decrementWins = {decrementWins} decrementLosses = {decrementLosses} />
                        </div>
                    }

                </div>
                 <div className="user-templates">
                    <div className="header">{getTranslation('unitTemplates')}</div>
                        {lists.filter(l => l.template_type == 'unit').map(l => <div className="user-template-row"><span className="user-list-name">{l.name}</span><div className="delete-button" onClick={() => deleteList(l.id)}>X</div></div>)}
                    <div className="header">{getTranslation('battlePlanTemplates')}</div>
                        {lists.filter(l => l.template_type == 'battle-plan').map(l => <div className="user-template-row"><span className="user-list-name">{l.name}</span><div className="delete-button" onClick={() => deleteList(l.id)}>X</div></div>)}
                    <div className="header">{getTranslation('customUnits')}</div>
                        {supporter &&<div style={{textAlign: 'center'}}>Create a new unit: <Link to="/createunitnew">New</Link> | <Link to="/createunit">Old</Link>
                        </div>}
                        {units.map(u => 
                        <div className="user-template-row">
                            <div>
                                {u.name}
                                <div><div>Edit Unit:</div><div><Link to={`/createUnitnew/${u.id}`}><span className="user-list-name">New</span></Link> | <Link to={`/createUnit/${u.id}`}><span className="user-list-name">Old</span></Link></div></div>
                                <div>Download Image:</div><div>{u.has_new_image && <><a href={`https://d26oqf9i6fvic.cloudfront.net/new-unit-cards/front/${u.id}.webp`}>New (Front)</a> | <a href={`https://d26oqf9i6fvic.cloudfront.net/new-unit-cards/back/${u.id}.webp`}>New (Back)</a></>}{!u.has_new_image &&<> | <a href={`https://d26oqf9i6fvic.cloudfront.net/units/${u.id}.webp`}>Old</a></>}</div>
                            </div> 
                            <span className="delete-button" onClick={() => deleteUnit(u.id)}>x</span>
                        </div>)}
                        {!supporter && <div style={{maxWidth: '300px'}}>Custom units now available for <a href="https://patreon.com/tabletopadmiral">Patreon</a> supporters! Sign up and activate your account to gain access.</div>}
                    <div className="header">Custom Commands</div>
                        {supporter &&<div style={{textAlign: 'center'}}><Link to="/createcommand">Create a new command card</Link></div>}
                        {commands.map(c =>                         
                        <div className="user-template-row">
                            <div>
                                <Link to={`/createCommand/${c.id}`}><span className="user-list-name">{c.name}</span></Link>
                                <br />
                                <a href={`https://d26oqf9i6fvic.cloudfront.net/commands/${c.id}.webp`}>Download Image</a>
                            </div> 
                            <span className="delete-button" onClick={() => deleteCommand(c.id)}>x</span>
                        </div>
                        )}
                        {!supporter && <div style={{maxWidth: '300px'}}>Custom commands now available for <a href="https://patreon.com/tabletopadmiral">Patreon</a> supporters! Sign up and activate your account to gain access.</div>}
                    <div className="header">Custom Upgrades</div>
                        {supporter &&<div style={{textAlign: 'center'}}><Link to="/createupgrade">Create a new upgrade card</Link></div>}
                        {upgrades.map(u =>                         
                        <div className="user-template-row">
                            <div>
                                <Link to={`/createupgrade/${u.id}`}><span className="user-list-name">{u.name}</span></Link>
                                <br />
                                <a href={`https://d26oqf9i6fvic.cloudfront.net/upgrades/${u.id}.webp`}>Download Image</a>
                            </div> 
                            <span className="delete-button" onClick={() => deleteUpgrade(u.id)}>x</span>
                        </div>
                        )}
                    {!supporter && <div style={{maxWidth: '300px'}}>Custom upgrades now available for <a href="https://patreon.com/tabletopadmiral">Patreon</a> supporters! Sign up and activate your account to gain access.</div>}
                    <div className="header">Custom Factions</div>
                        {supporter &&<div style={{textAlign: 'center'}}><Link to="/createfaction">Create a new faction</Link></div>}
                        {customFactions.map(f => 
                        <div className="user-template-row">
                            <div>
                                <Link to={`/createFaction/${f.id}`}><span className="user-list-name">{f.name}</span></Link>
                                <br />
                            </div> 
                            <span className="delete-button" onClick={() => deleteFaction(f.id)}>x</span>
                        </div>)}
                        {!supporter && <div style={{maxWidth: '300px'}}>Custom factions now available for <a href="https://patreon.com/tabletopadmiral">Patreon</a> supporters! Sign up and activate your account to gain access.</div>}
                        <div className="header">Custom Affiliations</div>
                        {supporter &&<div style={{textAlign: 'center'}}><Link to="/createfaction?type=affiliation">Create a new affiliation</Link></div>}
                        {customAffiliations.map(f => 
                        <div className="user-template-row">
                            <div>
                                <Link to={`/createFaction/${f.id}?type=affiliation`}><span className="user-list-name">{f.name}</span></Link>
                                <br />
                            </div> 
                            <span className="delete-button" onClick={() => deleteAffiliation(f.id)}>x</span>
                        </div>)}
                        {!supporter && <div style={{maxWidth: '300px'}}>Custom affiliations now available for <a href="https://patreon.com/tabletopadmiral">Patreon</a> supporters! Sign up and activate your account to gain access.</div>}
                   
                </div>            
                
            <div className="avatar-update">      
            <div className="header">{getTranslation('updateUIPreferences')}</div> 
            <div className='checkbox-wrapper' style={{width: 'inherit', textAlign: 'center'}}><input type="checkbox" id='slim-upgrade-slots' checked={slimUpgradeSlots} onChange={(e) => updateSlimUpgradeSlots(e.target.checked)} /> <label className='checkbox-label' for='slim-upgrade-slots'>{getTranslation('slimUpgrades')}</label></div>
            <div className='checkbox-wrapper' style={{width: 'inherit', textAlign: 'center'}}><input type="checkbox" id='slim-units' checked={slimUnits} onChange={(e) => updateSlimUnits(e.target.checked)} /> <label className='checkbox-label' for='slim-units'>{getTranslation('slimUnits')}</label></div>
            <div className='checkbox-wrapper' style={{width: 'inherit', textAlign: 'center'}}><input type="checkbox" id='full-images' checked={showUnitImages} onChange={(e) => updateShowUnitImages(e.target.checked)} /> <label className='checkbox-label' for='full-images'>{getTranslation('optionImages')}</label></div>
            <div className='checkbox-wrapper' style={{width: 'inherit', textAlign: 'center'}}><input type="checkbox" id='scroll-columns' checked={scrollColumns} onChange={(e) => updateScrollColumns(e.target.checked)} /> <label className='checkbox-label' for='scroll-columns'>{getTranslation('scrollColumns')}</label></div>
            <div className='checkbox-wrapper' style={{width: 'inherit', textAlign: 'center'}}><input type="checkbox" id='ignore-collection' checked={ignoreCollection} onChange={(e) => updateIgnoreCollection(e.target.checked)} /> <label className='checkbox-label' for='ignore-collection'>{getTranslation('ignoreCollection')}</label></div>
            <div className="header">{getTranslation('updateProfilePicture')}</div>     
            <ImageById style={{width: '50px', height: '50px', borderRadius: '50%' ,objectFit: 'cover'}} id={userId} type="avatar" />
            
                <label className="custom-file-upload">
                <input
                        className="upload-button"
                        type="file"
                        name="image"
                        onChange={uploadPhoto}
                />
                {getTranslation('chooseFile')}
            </label><br />
            <br /><br />
            <div className="header">{getTranslation('patreonSupport')}</div>     
            {supporter && supporterUntil && new Date(supporterUntil) < new Date() && <div>Your supporter status ended on {supporterUntil?.substring(0,10)}. Join the <a href="https://patreon.com/tabletopadmiral">Patreon</a> or make a yearly tip of $12 to <a href="https://paypal.me/tabletopadmiral">paypal.me/tabletopadmiral</a> (include your username in the note) to make sure there's no gap in access.</div>}
            {!supporter && <div>Not yet a supporter? Consider chipping in $1 or so per month at <a href="https://patreon.com/tabletopadmiral">Patreon</a>!<br />I'd really appreciate it!<br />If you're already a supporter, activate below.<br /><br /></div>}
            <input value={email} onChange={(e) => setEmail(e.target.value)} />
            {supporter && <div style={{color: 'green'}}>{getTranslation('patreonActive')}</div>}
            <br />
            {!supporter && <div><div style={{cursor: 'pointer', border: '1px solid black', padding: '3px', borderRadius: '3px', width: '150px', margin: 'auto', boxShadow: '1px 1px 3px black', marginTop: '3px'}} onClick={checkPatreon}>Verify</div><div> (If email address does not match your Patreon email, change it above. Your account email will stay the same.)</div></div>}
            </div>
            
        </div>
            </div>
        </div>
    )
}

export default UserLists